import React, {useState} from 'react'
import './Testimonial.css'
import { testimonialsData } from '../../data/testimonialsData'
import leftArrow from '../../assets/leftArrow.png'
import rightArrow from '../../assets/rightArrow.png'
import {motion} from 'framer-motion'

const Testimonial = () => {
  const transition={type:'string', duration:3}
  const [selected, setSelected] = useState(0);
  const tLength = testimonialsData.length;
  return (
    <div className='testimonials'>
        <div className="left-side">
            <span>Testimonial</span>
            <span className='stroke-text'>What they</span>
            <span>say about us</span>
            <motion.span
             key={selected}
             initial={{opacity:0, x:-100}}
             animate={{opacity:1, x:0}}
             exit={{opacity:0, x:100}}
             transition={{...transition, duration:1}}
            >{testimonialsData[selected].review}</motion.span>
            <span>
              {/* <span style={{color: "var(--orange)"}}> */}
              <span>
                {testimonialsData[selected].name} 
              </span> 
              - {testimonialsData[selected].status}
            </span>

            {/* <div>I made the right choice by choosing the Fitclub and by choosing</div>
            <div>the right plan and program I already achieved my ideal body!</div>
            <div>Matthew Hendrickson</div><div> - Enterpreneur</div> */}
        </div>
        <div className="right-side">
          
          <motion.div
          initial={{opacity:0, x:-100}}
          whileInView={{opacity:1, x:0}}
          transition={{...transition, duration:2}}
          ></motion.div>
          
          <motion.div
          initial={{opacity:0, x:100}}
          whileInView={{opacity:1, x:0}}
          transition={{...transition, duration:2}}></motion.div>
          
          <motion.img 
          key={selected}
          initial={{opacity:0, x:100}}
          animate={{opacity:1, x:0}}
          exit={{opacity:0, x:-100}}
          transition={{...transition, duration:1}}
          src={testimonialsData[selected].image} alt="" />
          
          <div className='arrows'>
            <img onClick={()=>{
              selected===0
              ?setSelected(tLength-1)
              :setSelected((prev)=>prev-1)
              }}src={leftArrow} alt="" />
           
            <img onClick={()=>{
            selected===tLength-1
            ?setSelected(0)
            :setSelected((prev)=>prev+1)
          }}
          src={rightArrow} alt="" />
          </div>
        </div>
    </div>
  )
}

export default Testimonial