import React from 'react'
import Header from '../Header/Header' 
// import Heroheading from '../HeroHeading/Hero_heading'
// import Subs from '../Subsheader/Subs'
import hero_image from "../../assets/hero_image.png"
import hero_image_back from "../../assets/hero_image_back.png"
import Heart from "../../assets/heart.png"
import Calories from "../../assets/calories.png"
import './Hero.css'
import {motion} from 'framer-motion'
import NumberCounter from 'number-counter'

const Hero = () => {
  const mobile=window.innerWidth<=768?true:false;
  const transition={type: 'string', duration: 3}
  return (
    <div className='hero' id='home'>

      <div className="blur blur-h"></div>
      <div className='left-h'>
        <Header/>

        {/* or you can call Subs.jsx */}
        {/* <Subs/> */}
        <div className='the-best-ad'>      
          <motion.div
          initial={{left:mobile?'165px':'238px'}}
          whileInView={{left: '8px'}}
          transition={{...transition, type:'tween'}}></motion.div>
      
        <span>the best fitness club in the town</span>
    </div>

    {/* shape your ideal body heading */}
    {/* <Heroheading/> */}
    <div className="hero-text">
      <div>
        <span className='stroke-text'>Shape </span>
        <span>Your</span>
        </div>
        
        <div>
          <span>Ideal Body</span>
          </div>
          <div>
            <span>In here we will help you to shape and build your ideal body and live up your life to fullest </span>
            </div>
    </div>

    {/* figures on page */}
    <div className="figures">
      <div>
        <span><NumberCounter end={140} start={100} delay={4} preFix="+"/></span>
        <span>expert coaches</span>
      </div>
      <div>
        <span><NumberCounter end={978} start={800} delay={4} preFix="+"/></span>
        <span>members joined</span>
      </div>
      <div>
        <span><NumberCounter end={50} start={0} delay={4} preFix="+"/></span>
        <span>fitness programs</span>
      </div>
    </div>

     {/* Hero buttons */}
     <div className="hero-buttons">
        <div className="btn">Get Started</div>
        <div className="btn">Learn More</div>
      </div>
      
      </div>

      <div className='right-h'>
        <div className="btn">Join Now</div>

        <motion.div 
        initial={{right:'-1rem'}}
        whileInView={{right:'6rem'}}
        transition={transition}
        className="heart-rate">
        <img src={Heart} alt=""/>
        <span>Heart Rate</span><span>116 bpm</span>
        </motion.div>

        {/* Hero Images */}
        <img src={hero_image} alt="" className='hero-image'/>
        <motion.img 
        initial={{right:'13rem'}}
        whileInView={{right:'20rem'}}
        transition={transition}
        src={hero_image_back} alt="" className='hero-image-back'/>

        {/* calories */}
       <motion.div 
       initial={{right:'37rem'}}
       whileInView={{right:'28rem'}}
       transition={transition}
       className="calories">
          <img src={Calories} alt="" />
            <div>
              <span>Calories Burned</span>
              <span>220 kcal</span>
        </div>
       </motion.div> 

    </div>
    </div>
  )
}

export default Hero